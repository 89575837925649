<template>
  <div>
    <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0 pt-0 pb-0">
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Cihaz Adı:-->
          <v-text-field
            v-model="deviceData.deviceName"
            label="Cihaz Adı:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>

        <!--  <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">

          <v-text-field
            label="Üretici firma:"
            v-model="formData.DeviceManufacture"
            required
            outlined
            disabled
            dense
            hidden-footer
            hidden-details
            hidden-hint
          />
        </v-col> -->

        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Cihaz Seri Numarası:-->
          <v-text-field
            v-model="deviceData.serial"
            label="Cihaz Seri Numarası:"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Zaman Aralığı:-->
          <v-select
            v-model="deviceData.timeInterval"
            label="Zaman Aralığı:"
            autofocus
            outlined
            :items="timeIntervalList"
            dense
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>

    <!--  <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0">
        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">

          <v-text-field
            label="Model:"
            v-model="formData.Model"
            required
            outlined
            disabled
            dense
            hidden-footer
            hidden-details
            hidden-hint
          />
        </v-col>
        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">

          <v-text-field
            label="Ip adres:"
            v-model="formData.ipAddress"
            :counter="10"
            required
            outlined
            dense
            hidden-footer
            hidden-details
            hidden-hint
          />
        </v-col>

        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">

          <v-text-field
            label="Port:"
            v-model="formData.port"
            required
            outlined
            dense
            hidden-footer
            hidden-details
            hidden-hint
          />
        </v-col>

      </v-col>
    </v-row>
 -->
    <v-row class="mb-0 mt-0 pt-4 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0 pt-0 pb-0">
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Datalogger Kullanıcı adı:-->
          <v-text-field
            v-model="deviceData.LoggerName"
            label=" Datalogger Kullanıcı adı:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Datalogger Şifre:-->
          <v-text-field
            v-model="deviceData.LoggerPass"
            label=" Datalogger Şifre:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Tarih formatı:-->
          <v-select
            v-model="deviceData.dateFormaterModel"
            label="Tarih formatı:"
            autofocus
            outlined
            :items="dateFormaterList"
            dense
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mb-0 mt-0 pt-4 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0 pt-0 pb-0">
        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">
          <!--Ftp User:-->
          <v-text-field
            v-model="deviceData.ftpuser"
            required
            label="Ftp User:"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">
          <!--Ftp Şifre:-->
          <v-text-field
            v-model="deviceData.ftppass"
            label="Ftp Şifre:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">
          <!--Ftp Dosya:-->
          <v-text-field
            v-model="deviceData.ftpfile"
            label="Ftp Dosya Yolu:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">
          <!--Ftp Dosya adı:-->
          <v-select
            v-model="deviceData.ftpfilename"
            label=" Ftp Dosya adı:"
            autofocus
            outlined
            :items="fileFormaterList"
            dense
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col cols="12" md="12" class="text-right">
        <v-btn color="primary" dense outlined @click="saveForm">
          Cihazı Kaydet
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["deviceData", "santralData"],
  data: () => ({
    formData: {
      deviceName: "",
      ipAddress: "",
      port: "",
      serial: "",
      dateFormaterModel: "",
      Model: "",
      status: "Active",
      timeInterval: null,
      url: "",
      ftpfile: "/",
      ftppass: "",
      ftpuser: "",
      ftpfilename: ""
    },
    timeIntervalList: [],
    dateFormaterList: [
      { text: "Y-m-d H:M:S", value: "%Y-%m-%d %H:%M:%S" },
      { text: "d-m-Y H:M:S", value: "%m-%m-%Y %H:%M:%S" }
    ],
    fileFormaterList: [
      { text: "YmdH", value: "%Y%m%d%H" },
      { text: "dmYH", value: "%d%m%Y%H" }
    ]
  }),
  watch: {
    deviceData() {
      console.log(this.deviceData);
      this.setForm();
    }
  },
  mounted() {
    this.setForm();
  },
  methods: {
    setForm() {
      console.log(this.deviceData);
      /*  this.formData = {
        deviceName: this.deviceData.deviceName,
        ipAddress: this.deviceData.ipAddress,
        port: this.deviceData.port,
        serial: this.deviceData.serial,
        dateFormaterModel: this.deviceData.serial,
        Model: "",
        status: "Active",
        timeInterval: null,
        url: "",
        ftpfile: "/",
        ftppass: "",
        ftpuser: "",
        ftpfilename: ""
      };

      this.formData.timeInterval = this.deviceData.timeInterval;
      this.formData.DeviceModel = this.deviceData.DeviceModel;
      this.formData.Model = this.deviceData.Model;
      this.formData.ftpfilename = this.deviceData.ftpfilename;
      this.formData.DeviceManufacture = this.deviceData.DeviceManufacture;
      this.formData.ftpfile = this.deviceData.ftpfile;
      this.formData.ftppass = this.deviceData.ftppass;
      this.formData.ftpuser = this.deviceData.ftpuser;
      this.formData.ftpDateType = this.deviceData.ftpDateType;
      this.formData = { ...this.formData, ...this.santralData };
      console.log(this.formData); */
      // ftpfile: '/i2utyy',
      // ftppass: 'i2utyy',
      // ftpuser: 'admin_i2utyy',
      // eslint-disable-next-line radix
      let cycle = 5;
      if (this.deviceData.timeInterval) {
        cycle = parseInt(this.deviceData.timeInterval);
      }

      const timeIntervalList = [];
      for (let i = cycle; i <= 60; i += cycle) {
        timeIntervalList.push({
          text: `${i} dk`,
          value: i
        });
      }

      this.timeIntervalList = timeIntervalList;
    },

    saveForm() {
      this.$emit("savedataloger", this.deviceData);
    }
  }
};
</script>

<style></style>
