<template>
  <div>
    <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0 pt-0 pb-0">
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Cihaz Adı:-->
          <v-text-field
            v-model="formData.deviceName"
            label=" Cihaz Adı:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Üretici firma:-->
          <v-text-field
            v-model="formData.DeviceManufacture"
            label="Üretici firma:"
            required
            outlined
            disabled
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Cihaz Seri Numarası:-->
          <v-text-field
            v-model="formData.serial"
            label="Cihaz Seri Numarası:"
            outlined
            dense
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mb-0 mt-0 pt-1 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0">
        <!--  <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">
         
          <v-text-field
            v-model="formData.Model"
            label="Model:"
            required
            outlined
            disabled
            dense
            hide-details
          />
        </v-col> -->
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Ip adres:-->
          <v-text-field
            v-model="formData.ipAddress"
            label="Ip adres:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Port:-->
          <v-text-field
            v-model="formData.port"
            label="Port:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Zaman Aralığı:-->
          <v-select
            v-model="formData.timeInterval"
            label="Zaman Aralığı:"
            autofocus
            outlined
            :items="timeIntervalList"
            dense
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0">
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Datalogger Kullanıcı adı:-->
          <v-text-field
            v-model="formData.LoggerName"
            label="Datalogger Kullanıcı adı:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Datalogger Şifre:-->
          <v-text-field
            v-model="formData.LoggerPass"
            label="Datalogger Şifre:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Tarih formatı:-->
          <v-select
            v-model="formData.dateFormaterModel"
            label="Tarih formatı:"
            autofocus
            outlined
            :items="dateFormaterList"
            dense
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0">
        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">
          <!--Ftp User:-->
          <v-text-field
            v-model="formData.ftpuser"
            label="Ftp User:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">
          <!--Ftp Şifre:-->
          <v-text-field
            v-model="formData.ftppass"
            label="Ftp Şifre:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">
          <!--Ftp Dosya:-->
          <v-text-field
            v-model="formData.ftpfile"
            label="Ftp Dosya:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">
          <!--Ftp dosya tipi:-->
          <v-select
            v-model="formData.ftpfilename"
            label="Ftp dosya tipi:"
            autofocus
            outlined
            :items="ftpFilenameList"
            dense
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>
    <v-row class="mb-0 ml-4 mt-0 pt-0 pb-0">
      <v-col cols="12" md="12" class="text-right">
        <v-btn color="primary" @click="saveForm" dense outlined> Cihazı Kaydet </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["deviceData", "santralData"],
  data: () => ({
    formData: {
      deviceName: "",
      ipAddress: "",
      port: "",
      serial: "",
      dateFormaterModel: "",
      Model: "",
      status: "Active",
      timeInterval: null,
      url: "",
      ftpfile: "/",
      ftppass: "",
      ftpuser: "",
      ftpfilename: ""
    },
    timeIntervalList: [],
    dateFormaterList: [
      { text: "Y-m-d H:M:S", value: "%Y-%m-%d %H:%M:%S" },
      { text: "d-m-Y H:M:S", value: "%m-%m-%Y %H:%M:%S" }
    ],
    ftpFilenameList: [
      { text: "YmdH", value: "%Y%m%d%H" },
      { text: "dmYH", value: "%d%m%Y%H" },
      { text: "ymdH", value: "%y%m%d%H" }
    ]
  }),
  watch: {
    deviceData() {
      this.setForm();
    }
  },
  mounted() {
    this.setForm();
  },
  methods: {
    setForm() {
      console.log(this.deviceData);
      this.formData.timeInterval = this.deviceData.UploadCycleTime;
      this.formData.DeviceModel = this.deviceData.DeviceModel;
      this.formData.Model = this.deviceData.Model;
      this.formData.DeviceManufacture = this.deviceData.DeviceManufacture;
      this.formData.ftpfile = this.deviceData.ftpfile;
      this.formData.ftppass = this.deviceData.ftppass;
      this.formData.ftpuser = this.deviceData.ftpuser;
      this.formData.ftpfilename = this.deviceData.ftpfilename;
      this.formData = { ...this.formData, ...this.santralData };

      // ftpfile: '/i2utyy',
      // ftppass: 'i2utyy',
      // ftpuser: 'admin_i2utyy',
      // eslint-disable-next-line radix
      const cycle = parseInt(this.deviceData.timeInterval);
      const timeIntervalList = [];
      for (let i = cycle; i <= 60; i += cycle) {
        timeIntervalList.push(i);
      }

      this.timeIntervalList = timeIntervalList;
    },

    saveForm() {
      console.log(this.formData);
      this.$emit("savedataloger", this.formData);
    }
  }
};
</script>

<style></style>
