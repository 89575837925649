<template>
  <div>
    <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0 pt-0 pb-0">
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Cihaz Adı:-->
          <v-text-field
            label="Cihaz Adı:"
            v-model="formData.deviceName"
            required
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Üretici firma:-->
          <v-text-field
            label="Üretici firma:"
            v-model="formData.DeviceManufacture"
            required
            outlined
            disabled
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Cihaz Seri Numarası:-->
          <v-text-field
            label="Cihaz Seri Numarası:"
            v-model="formData.serial"
            outlined
            dense
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0">
        <!--  <v-col cols="12" md="3" class="mb-0 mt-0 pt-0 pb-0">
          
          <v-text-field
            label=" Model:"
            v-model="formData.DeviceModel"
            required
            outlined
            disabled
            dense
            hide-details
          />
        </v-col> -->
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Ip adres:-->
          <v-text-field
            label="Ip adres:"
            v-model="formData.ipAddress"
            required
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Port:-->
          <v-text-field
            v-model="formData.port"
            label="Port:"
            required
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Zaman Aralığı:-->
          <v-select
            label="Zaman Aralığı:"
            v-model="formData.timeInterval"
            autofocus
            outlined
            :items="timeIntervalList"
            dense
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mb-0 mt-0 pt-0 pb-0">
      <v-col class="d-flex col-12 mb-0 mt-0 pt-0 pb-0">
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Datalogger Kullanıcı adı:-->
          <v-text-field
            label=" Datalogger Kullanıcı adı:"
            v-model="formData.LoggerName"
            required
            outlined
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Datalogger Şifre:-->
          <v-text-field
            label="Datalogger Şifre:"
            v-model="formData.LoggerPass"
            required
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" md="4" class="mb-0 mt-0 pt-0 pb-0">
          <!--Durum:-->
          <v-select
            label="Durum:"
            v-model="formData.status"
            autofocus
            outlined
            :items="['Active', 'Passive']"
            dense
            hide-details
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row class="mb-0 ml-4 mt-0 pt-0 pb-0">
      <v-col cols="12" md="12" class="text-right">
        <v-btn color="primary" @click="saveForm" dense outlined> Cihazı Kaydet </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["deviceData", "santralData"],
  data: () => ({
    formData: {
      deviceName: "",
      ipAddress: "",
      port: "",
      serial: "",
      manufacture: "",
      Model: "",
      status: "Active",
      timeInterval: null,
      url: "/nqzva__PRX__nqzva/snmp.med"
    },
    timeIntervalList: []
  }),
  watch: {
    deviceData() {
      // console.log("deviceData");
      // console.log(this.deviceData);
    }
  },
  created() {
    this.setForm();
  },

  methods: {
    setForm() {
      console.log(this.deviceData);

      this.formData = { ...this.formData, ...this.deviceData };
      console.log(this.formData);

      // eslint-disable-next-line radix
      const cycle = parseInt(this.deviceData.timeInterval);
      const timeIntervalList = [];
      for (let i = cycle; i <= 60; i += cycle) {
        timeIntervalList.push(i);
      }

      this.timeIntervalList = timeIntervalList;
    },

    saveForm() {
      console.log(this.formData);
      this.$emit("savedataloger", this.formData);
    }
  }
};
</script>

<style></style>
