/* eslint-disable prefer-destructuring */
<template>
  <v-container class="container--fluid text-center">
    <v-card class="mt-5">
      <v-card-title class="">
        <span class="blue--text text-h6"> Datalogger Ekle </span>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <div class="col-12 d-flex">
          <v-btn class="" outlined color="indigo" dense @click="createDevice">
            <v-icon left>
              mdi-plus
            </v-icon>
            Cihaz Ekle
          </v-btn>
          <div class="col-2 mt-0 pt-0">
            <v-select
              v-model="santralModel2"
              :items="santralList"
              label="Santral Listesi"
              dense
              outlined
              hide-details
              @click="getSantralList"
            />
          </div>
        </div>

        <v-data-table
          :headers="devheaders"
          :items="devdesserts"
          :items-per-page="itemPerPage"
          class="elevation-1"
          dense
        >
          <template v-slot:item.santralCode="{ item }">
            <v-icon small class="mr-2" @click="editItemOpen(item)">
              mdi-pencil-circle
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="blue--text"> <h2>Datalogger Ekle</h2></span>
        </v-card-title>
        <v-card-text>
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Tip
              </v-stepper-step>

              <v-divider />

              <v-stepper-step :complete="e1 > 2" step="2">
                İnfo
              </v-stepper-step>

              <v-divider />
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-col class="col-6">
                  <v-select
                    v-model="santralModel"
                    class="col-5"
                    :items="santralList"
                    label="Santral Listesi"
                    outlined
                    dense
                  />

                  <v-select
                    v-if="santralModel"
                    v-model="dataloggerModel"
                    class="col-5"
                    :items="dataloggerList"
                    label="Cihaz Listesi"
                    outlined
                    dense
                  />
                  <v-col class="d-flex">
                    <v-btn color="primary" dense outlined @click="e1 = 2">
                      Devam
                    </v-btn>
                    <hr />
                    <hr />
                    <hr />
                    <v-btn color="primary" text dense outlined @click="dialogClose">
                      Kapat
                    </v-btn>
                  </v-col>
                </v-col>
              </v-stepper-content>

              <v-stepper-content step="2">
                <div class="mb-3 mt-3 ml-1 mr-1">
                  <component
                    :is="dataloggerComponentName"
                    :device-data="dataloggerData[dataloggerModel]"
                    :bind="true"
                    @savedataloger="savedatalogerlFunc"
                  />

                  <v-col class="d-flex">
                    <v-btn color="primary" dense outlined @click="e1 = 1">
                      Önceki
                    </v-btn>
                    <hr />
                    <hr />
                    <hr />
                    <v-btn color="primary" text dense outlined @click="dialogClose">
                      Kapat
                    </v-btn>
                  </v-col>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { getDeviceList } from "@/api/Request/farm";

import {
  setDataloggerCreate,
  setDataloggerDelete,
  getDataloggerList
} from "@/api/Request/datalogger";

import { getSantralList } from "@/api/Request/santral";

import SunguardBusiness2 from "@/views/components/Datalogger/SunguardBusiness2.vue";
import SunguardBusiness3 from "@/views/components/Datalogger/SunguardBusiness3.vue";
import HuaweiSmartLogger3000 from "@/views/components/Datalogger/HuaweiSmartLogger3000.vue";
import SolarifyDatalogger from "@/views/components/Datalogger/SolarifyDatalogger.vue";
import Danfoss1 from "@/views/components/Datalogger/Danfoss1.vue";
import SMA1 from "@/views/components/Datalogger/SMA1.vue";

import helper from "@/api/helper";

export default {
  components: {
    SunguardBusiness3,
    SunguardBusiness2,
    HuaweiSmartLogger3000,
    SolarifyDatalogger,
    Danfoss1,
    SMA1
  },
  data: () => ({
    devheaders: [],
    devdesserts: [],
    itemPerPage: 10,
    dialog: false,
    search: "",
    valid: true,
    lazy: true,
    edit: false,
    editdialog: false,
    e1: "1",
    prefix: "",
    companyCode: "",
    santralCode: "",
    dataloggerComponentName: "",
    dataloggerModel: "",
    dataloggerList: [],
    dataloggerData: {},
    dataloggerComponentList: {},
    santralData: {},
    santralList: [],
    santralModel: null,
    santralModel2: null
  }),
  watch: {
    dataloggerModel() {
      this.dataloggerComponentName = this.dataloggerComponentList[this.dataloggerModel];
    },

    santralModel2() {
      this.getDatalogger();
    },
    santralModel() {
      this.getSantralInfo();
    }
  },
  created() {},
  mounted() {
    this.dataloggerComponentList = {
      Sunguard1: "SunguardBusiness3",
      Sunguard2: "SunguardBusiness2",
      Huawei1: "HuaweiSmartLogger3000",
      Danfoss1: "Danfoss1",
      SMA1: "SMA1",
      Solarify1: "SolarifyDatalogger"
    };

    this.selectedFarmFunc();
  },

  methods: {
    selectedFarmFunc() {
      const storage = window.localStorage;
      const userCompany = JSON.parse(window.atob(storage.getItem("user_company")));
      this.companyCode = userCompany.companyCode;
      this.prefix = userCompany.prefix;
      this.getDatalogger();
      this.getSantralList();
      this.getDevice();
      this.getSantralInfo();
    },
    dialogClose() {
      this.santralModel = "";
      this.dataloggerModel = "";
      this.dialog = false;
      this.e1 = "1";

      this.getDatalogger();
    },
    editItemOpen(item) {
      this.santralModel = item.santralCode;
      this.dataloggerModel = item.Model;
      this.dataloggerData[this.dataloggerModel] = item;
      this.dialog = true;
    },

    getSantralList() {
      this.headers = [
        { text: "Santral Adı", value: "santral" },
        { text: "Kurulu Güç", value: "installedPower" },
        { text: "Ekleme Tarihi", value: "createTime" },
        { text: "Status", value: "status" },
        { text: "", value: "santralCode" }
      ];

      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix
        }
      };
      // console.log(params);
      getSantralList(params).then(res => {
        const desserts = [];
        // console.log("............");
        // console.log(res);
        desserts.push({
          text: "Seçiniz",
          value: null
        });

        Object.keys(res.data.data).forEach(k => {
          desserts.push({
            text: res.data.data[k].santral,
            value: res.data.data[k].santralCode
          });
        });

        this.santralList = desserts;
      });
    },
    savedatalogerlFunc(item) {
      console.log(item);
      console.log("savedatalogerlFunc................");
      console.log(this.dataloggerData);
      console.log(this.dataloggerModel);
      console.log(this.santralModel);
      console.log(this.santralData);
      console.log("...........................");
      const params = {
        condiniton: {
          prefix: this.prefix,
          santralCode: this.santralData[this.santralModel].santralCode,
          santral: this.santralData[this.santralModel].santral
        },
        data: { ...this.santralData[this.santralModel], ...item }
      };

      if (item.id !== undefined) {
        params.condiniton.id = item.id;
      } else {
        params.condiniton.deviceName = item.deviceName;
      }
      console.log(params);

      setDataloggerCreate(params).then(res => {
        setTimeout(() => {
          this.selectedFarmFunc();
        }, 500);
      });
    },
    createDevice() {
      this.dialog = true;
    },
    santralAdd(item) {
      this.deviceModel = item.dataLoggerType;
    },
    getSantralInfo() {
      const params = {
        condiniton: {
          prefix: this.prefix
          // santralCode: this.santralModel,
        }
      };

      getSantralList(params).then(res => {
        const santralData = {};

        if (res.data.success === "true") {
          if (res.data.data.length > 0) {
            Object.keys(res.data.data).forEach(i => {
              santralData[res.data.data[i].santralCode] = res.data.data[i];
            });

            this.santralData = santralData;
          }
        }
      });
    },

    // Datalogger listesi çek
    getDevice() {
      const params = {
        condiniton: {
          DeviceType: "Datalogger"
        }
      };

      getDeviceList(params).then(res => {
        const devList = [];
        const deviceData = {};
        Object.keys(res.data.data).forEach(i => {
          devList.push({
            text: `${res.data.data[i].DeviceManufacture} ${res.data.data[i].DeviceModel}`,
            value: res.data.data[i].Model
          });
          deviceData[res.data.data[i].Model] = res.data.data[i];
        });
        this.dataloggerList = devList;
        this.dataloggerData = deviceData;
      });
    },
    deleteItem(item) {
      const params = {
        condiniton: {
          id: item.id,
          prefix: this.prefix
        }
      };
      setDataloggerDelete(params).then(res => {
        setTimeout(() => {
          this.selectedFarmFunc();
        }, 500);
      });
    },
    getDatalogger() {
      this.devheaders = [
        { text: "Santral ", value: "santral" },
        { text: "Cihaz", value: "deviceName" },
        { text: "Model", value: "Model" },
        { text: "DeviceManufacture", value: "DeviceManufacture" },
        { text: "Status", value: "status" },
        { text: "", value: "santralCode" }
      ];
      const params = {
        condiniton: {
          prefix: this.prefix
        }
      };

      if (!helper.is_Empty(this.santralModel2)) {
        params.condiniton.santralCode = this.santralModel2;
      }

      getDataloggerList(params).then(res => {
        // console.log(res);
        this.devdesserts = res.data.data;
      });
    }
  }
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
</style>
